<template>
    <div>
        <registration-box/>
        <div class="columns">
            <div class="column is-8-widescreen is-12-tablet">
                <div class="pt-5rem">
                    <div class="columns">
                        <div class="column is-12">
                            <div class="bordered-title">
                                <p class="title is-2">About Sewio Gala Evening</p>
                            </div>
                            <p class="normal-text">
                                We're turning 10 and we want you to be part of the festivities.
                                Dance the night away with us at the Sewio Gala,
                                as we honor 10 remarkable years filled with innovation, strong partnerships,
                                and achievements. Immerse yourself in dancing lessons,
                                enjoy live music, groove to DJ tunes, and take part in our charity auction.
                                A nice evening to remember the past and look forward to the future.
                                Let´s mark this milestone in style together.
                            </p>
                            <div class="bordered-title mt-5rem mb-2rem">
                                <p class="title is-2">Program</p>
                            </div>
                            <agenda-list/>
                            <div class="bordered-title mt-5rem">
                                <p class="title is-2">Sewio Gala Evening in Courtyard by Marriott Brno</p>
                            </div>
                            <p class="normal-text">
                                The gala will take place at the beautiful four-star Courtyard by Mariott hotel.
                                It´s located just five minutes by public transport from the center of Brno.
                                If you prefer traveling by car, the hotel offers convenient on-site parking.
                            </p>
                            <div class="mt-6">
                                <img
                                    src="../../assets/venue_place_mobile.jpg"
                                    alt="Venue"
                                    class="venue-image is-hidden-tablet"
                                />
                                <img
                                    src="../../assets/venue_place_desktop.jpg"
                                    alt="Venue"
                                    class="venue-image is-hidden-mobile"
                                />
                            </div>
                            <div class="bordered-title mt-5rem">
                                <p class="title is-2">What Is the Appropriate Attire for the Event?</p>
                            </div>
                            <p class="normal-text">
                                The dress code for our gala is more semi-formal rather than strictly black tie.
                                This means that men are encouraged to wear darker-colored suits,
                                while they can express their creativity through colorful accents.
                                Women have the option to wear shorter dresses, long dresses with subtle hints of color,
                                or even stylish overalls.
                            </p>
                            <div class="mt-6">
                                <img
                                    src="../../assets/dress_code_mobile.jpg"
                                    alt="Dress code"
                                    class="venue-image is-hidden-tablet"
                                />
                                <img
                                    src="../../assets/dress_code_desktop.jpg"
                                    alt="Dress code"
                                    class="venue-image is-hidden-mobile"
                                />
                            </div>
                            <div class="bordered-title mt-5rem">
                                <p class="title is-2">Looking for a Place to Stay?</p>
                            </div>
                            <p class="normal-text mb-2">
                                <a href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1700130784363&amp;key=GRP&amp;app=resvlink" target="_blank">Get a room directly at the Marriott hotel at a discounted price.</a>
                                <br>
                                <span class="text-small">(This discount is only valid till the 5<sup>th</sup> of January, 2024)</span>
                                <br><br>
                            </p>
                            <p class="normal-text">
                                Other accommodation options within walking distance from the event venue are:
                            </p>
                            <ul>
                                <li><a href="https://www.sharingham.cz/" target="_blank">Hotel Sharingham</a>, double rooms available from 1550 CZK</li>
                                <li><a href="https://www.fairhotel.cz/" target="_blank">Fairhotel</a>, double rooms available from 2600 CZK</li>
                                <li><a href="https://www.hotelinternational.cz/" target="_blank">Hotel International</a>, double rooms available from 2500 CZK</li>
                            </ul>
                            <br><br>
                            <img
                                src="../../assets/rooms_mobile.jpg"
                                alt="Marriott hotel rooms"
                                class="venue-image is-hidden-tablet"
                            />
                            <img
                                src="../../assets/rooms_desktop.jpg"
                                alt="Marriott hotel rooms"
                                class="venue-image is-hidden-mobile"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RegistrationBox from "@/components/molecules/RegistrationBox.vue";
import Agenda from "@/components/pages/Agenda.vue";
import AgendaList from "@/components/molecules/AgendaList.vue";

export default {
    components: {AgendaList, Agenda, RegistrationBox}
}
</script>

<style scoped lang="scss">
@import "../../scss/variables";

ul {
    list-style: disc;
    padding-left: 2.5rem;
    padding-top: 1rem;
}

a {
    color: $secondary !important;
    &:hover {
        color: $primary !important;
        text-decoration: underline !important;
    }
}

.bordered-title {
    border-bottom: 2px solid $secondary;
    margin-bottom: 3rem;
}

.mb-2rem {
    margin-bottom: 2rem;
}

.title.is-2 {
    font-size: 3rem;
    font-weight: 200;
    padding-bottom: 1rem;
}

.registration-box-wrapper + .columns {
    padding-right: 2rem;
}

</style>