<template>
    <div class="registration-box-wrapper">
        <div class="columns">
            <div class="column is-4-widescreen is-12-tablet registration-box-position">
                <div class="registration-box">
                    <div>
                        <template v-if="registrationDisabled">
                            <h1 class="title has-text-centered text-white">Registration Closed for Sewio Gala Event</h1>
                            <div class="columns">
                                <div class="column is-12">
                                    Thanks for your interest in the Sewio Gala event. Sadly, our online sign-up is now closed. But don't worry, we've still got your back.<br><br>
                                    Want to join the fun? Just shoot an email to our Gala Coordinator, Lenka Vesela, at <a href="mailto:lenka.vesela@sewio.net">lenka.vesela@sewio.net</a>. She'll be more than happy to register you so that you can celebrate with us.
                                </div>
                            </div>

                        </template>
                        <template v-else>
                            <ValidationObserver v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(submit)">
                                    <h1 class="title has-text-centered text-white">Celebrate With Us</h1>
                                    <div class="columns">
                                        <div class="column is-6">
                                            <label for="first_name">First Name</label>
                                            <validation-provider name="First Name" rules="required" v-slot="{ errors }">
                                                <input class="input" name="first_name" type="text" v-model="firstName"/>
                                                <span class="help is-danger" v-if="errors[0]">{{ errors[0] }}</span>
                                            </validation-provider>
                                        </div>
                                        <div class="column is-6">
                                            <label for="last_name">Last Name</label>
                                            <validation-provider name="Last Name" rules="required" v-slot="{ errors }">
                                                <input class="input" name="last_name" type="text" v-model="lastName"/>
                                                <span class="help is-danger" v-if="errors[0]">{{ errors[0] }}</span>
                                            </validation-provider>
                                        </div>
                                    </div>
                                    <transition-collapse-height>
                                        <div v-if="isAnotherGuest">
                                            <div class="columns">
                                                <div class="column is-6">
                                                    <label for="first_name">First Name</label>
                                                    <validation-provider name="First Name +1" rules="required" v-slot="{ errors }">
                                                        <input class="input" name="first_name_plus_one" type="text" v-model="guestFirstName"/>
                                                        <span class="help is-danger" v-if="errors[0]">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </div>
                                                <div class="column is-6">
                                                    <label for="first_name">Last Name</label>
                                                    <validation-provider name="Last Name +1" rules="required" v-slot="{ errors }">
                                                        <input class="input" name="last_name_plus_one" type="text" v-model="guestLastName"/>
                                                        <span class="help is-danger" v-if="errors[0]">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </div>
                                            </div>
                                        </div>
                                    </transition-collapse-height>
                                    <div class="columns">
                                        <div class="column is-12">
                                            <label for="email">Email</label>
                                            <validation-provider name="Email" rules="required|email" v-slot="{ errors }">
                                                <input class="input" name="email" type="text" v-model="email"/>
                                                <span class="help is-danger" v-if="errors[0]">{{ errors[0] }}</span>
                                            </validation-provider>
                                        </div>
                                    </div>
                                    <div class="columns pt-4">
                                        <div class="column is-12 has-text-centered">
                                            <button class="btn-secondary w-100" type="button" @click="addGuest">
                                                <span v-if="showAddGuestButton">+Add Another Guest</span>
                                                <span v-else>Cancel</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="columns">
                                        <div class="column is-12 has-text-centered">
                                            <b>Number of Guests: {{ numberOfTickets }}</b>
                                        </div>
                                    </div>
                                    <div class="columns">
                                        <div class="column is-12 has-text-centered">
                                            <button class="btn-primary w-100" type="submit" :disabled="loading">Register</button>
                                        </div>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TransitionCollapseHeight from "@/components/molecules/TransitionCollapseHeight.vue";
import axios from "axios";
import registration from "@/config/registration";

export default {
    name: 'RegistrationBox.vue',
    components: {TransitionCollapseHeight},
    data() {
        return {
            firstName: null,
            lastName: null,
            email: null,
            isAnotherGuest: false,
            showAddGuestButton: true,
            numberOfTickets: 1,
            guestFirstName: null,
            guestLastName: null,
            api: process.env.VUE_APP_API_URL,
            loading: false,
            registrationDisabled: !registration.registration_open,
        }
    },
    methods: {
        submit() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            axios.post(this.api + "register", {
                first_name: this.firstName,
                last_name: this.lastName,
                email: this.email,
                guest_first_name: this.guestFirstName,
                guest_last_name: this.guestLastName,
            }).then((response) => {
                this.$router.push({path: '/thank-you'});
            }).finally(() => {
                this.loading = false;
            });
        },
        addGuest() {
            this.isAnotherGuest = !this.isAnotherGuest;
            this.showAddGuestButton = !this.showAddGuestButton;
            if (!this.isAnotherGuest) {
                this.guestFirstName = null;
                this.guestLastName = null;
            }
            this.numberOfTickets = this.isAnotherGuest ? 2 : 1;
        },
    },
}
</script>

<style scoped lang="scss">
@import "../../scss/variables";
$box-top-offset: 600px;

.registration-box-wrapper {
    font-weight: normal;
    position: sticky;
    top: calc(150px + $box-top-offset);
    width: 100%;
    z-index: 100;
    @media (max-width: $sticky-registration-form-breakpoint) {
        position: static;
        top: 0;
    }
    &>.columns {
        position: absolute;
        width: 100%;
        @media (min-width: $sticky-registration-form-breakpoint) {
            height: 0;
        }
        @media (max-width: $sticky-registration-form-breakpoint) {
            position: static;
            width: auto;
        }
    }
    .registration-box-position {
        position: relative;
        top: -$box-top-offset;
        height: fit-content;
        margin-left: auto;
        display: flex;
        justify-content: center;
        @media (max-width: $sticky-registration-form-breakpoint) {
            position: static;
            top: 0;
        }
    }
    .registration-box {
        background-color: $secondary;
        box-shadow: 20px 20px 60px #023A1514;
        border-radius: 20px;
        margin-left: 12px;
        padding: 3rem 2rem;
        position: relative;
        @media (max-width: $sticky-registration-form-breakpoint) {
            margin-left: 0;
        }
        min-width: min(332px, 80vw);
        max-width: 436px;
        min-height: 500px;
    }
}
.has-text-centered {
    b {
        color: $primary-light
    }
}

</style>