<template>
    <div>
        <footer>
            <div class="section">
                <div class="container">
                    <div class="columns is-align-items-end">
                        <div class="column is-4">
                            <p class="title is-size-5 question">
                                Got a question? <br/> Don’t hesitate to contact me.
                            </p>
                            <div class="columns footer--contact--info">
                                <div class="column is-narrow">
                                    <!--                    TODO change IMAGE-->
                                    <img
                                        src="../../assets/vesela.png"
                                        alt="Andrea Sura"
                                        class="footer--contact--image"
                                    />
                                </div>
                                <div class="column">
                                    <p>
                                        <b>Lenka Veselá</b> | Gala Coordinator <br/>
                                        <a href="tel:+420722768646">+420 722 768 646</a><br/>
                                        <a href="mailto:gala@sewio.net">gala@sewio.net</a>
                                        <br/>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="column is-4 is-offset-4 has-text-right">
                            <p>
                                <b>Sewio Networks s.r.o.</b><br/>
                                JIC INMEC, Purkynova 649/127 <br/>
                                612 00, Brno, Czech Republic <br/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <section class="footnote">
            <div class="section">
                <div class="container">
                    <div class="columns">
                        <div class="column is-4">
                            <p class="footnote--copyright is-size-7">
                                Copyright 2023 Sewio Networks | All Rights Reserved
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

export default {
    name: "Footer",
};
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

footer,
.footnote {
    a {
        &:after {
            content: none;
        }
    }
}

.is-align-items-end {
    align-items: end !important;
}

footer {
    background-color: $primary;
    position: relative;
    z-index: 100;

    p {
        color: $primary-light !important;
    }

    a {
        color: $primary-light;
    }

    .title {
        margin-bottom: 0 !important;

        &.question {
            font-size: 1.5rem;
            font-weight: 300;
        }
    }

    .columns {
        align-items: flex-start;
    }

    img {
        width: 12rem;
    }

    .footer {
        &--contact--info {
            margin-top: 2rem;
            align-items: center;
            .column.is-narrow {
                padding-top: 0;
                padding-bottom: 0;
            }

            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &--contact--image {
            width: auto;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
        }
    }
}

.footnote {
    background-color: $primary-dark;
    color: white;

    &--copyright {
        color: $primary-light;
    }

    .section {
        padding-top: 2rem;
        padding-bottom: 3rem;
    }
}
</style>
