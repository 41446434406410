export default {
  homepage: {
    title: "Registration",
    description:
      "Register for Sewio Gala",
  },
  'thank-you': {
    title: "Thank you",
    description:
      "Thank you for registration",
  },
  gallery: {
    title: "Gallery",
    description: "See the photos from Sewio Gala",
  },
};
