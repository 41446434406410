<template>
    <div class="hero-info">
        <span>January 19<sup>th</sup></span>
        <span>2024</span>
        <span>18:00-3:00</span>
        <span>HOTEL COURTYARD BY MARRIOTT</span>
        <span>BRNO</span>
    </div>
</template>

<script>
export default {
    name: 'HeroInfo.vue',
}
</script>

<style scoped lang="scss">
@import "../../scss/variables";
.hero-info {
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 0px;
    padding: 4px 12px;
    span {
        text-align: center;
        flex-grow: 1;
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        position: relative;
        border-right: 2px solid $secondary;
        &:last-child {
            border-right: none;
        }
    }
    @media (max-width: 768px) {
        display: grid;
        align-items: center;
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(3, 1fr);
        border-bottom: 2px solid $secondary;
        padding: 0;
        span {
            border: none;
        }

        span {
            padding-top: 10px;
            padding-bottom: 10px;
            width: 100%;
        }

        span:nth-child(1), span:nth-child(2) {
            padding: 0;
            margin-top: 10px;
            border-right: 2px solid $secondary;
            margin-bottom: 10px;
        }

        span:nth-child(3) {
            border-right: none;
        }

        span:nth-child(4) {
            grid-column: span 3;
            border-radius: 0;
            border-bottom: 2px solid $secondary;
            border-top: 2px solid $secondary;
        }

        span:nth-child(5) {
            grid-row: 3;
            grid-column: 2;
        }
    }
}
</style>