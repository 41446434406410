<template>
    <header id="header">
        <img class="img-bg" src="../../assets/header_image_bg.jpg" ref="bg-img"/>
        <div class="container">
            <nav class="navbar" role="navigation" aria-label="main navigation">
                <div class="navbar-brand">
                    <a href="https://www.sewio.net/" target="_blank" class="navbar-item">
                        <img src="../../assets/Sewio_Gala_Logo_-_White_-_RGB.svg" alt="Sewio logo" class="logo"/>
                    </a>
                    <router-link to="/" class="navbar-item">
                        <img src="../../assets/logo.svg" alt="Sewio 10 Years Anniversary logo" class="logo"/>
                    </router-link>
                </div>
                <div v-if="content.gallery.show" class="gallery-link">
                    <router-link to="/gallery" class="text-white">
                        <span class="is-hidden-mobile">Gallery</span>
                        <img src="../../assets/gallery.svg" alt="Gallery" class="is-hidden-tablet text-white"/>
                    </router-link>
                </div>
            </nav>
        </div>
    </header>
</template>

<script>
import debounce from "buefy/src/utils/debounce";
import content from "../../content";

export default {
    name: "Header",
    data() {
        return {
            content: content,
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.setMaxHeight();
        });
        this.setMaxHeight = debounce(this.setMaxHeight, 100);
        window.addEventListener('resize', this.setMaxHeight);
    },
    methods: {
        setMaxHeight() {
            const headerHeight = window.document.getElementById('header').offsetHeight;
            const sectionHeight = window.document.getElementById('hero-section').offsetHeight;
            const maxHeight = headerHeight + sectionHeight + 200;

            this.$refs['bg-img'].style.maxHeight = `${maxHeight}px`;
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setMaxHeight);
    },
    watch: {
        $route(newValue, oldValue) {
            this.setMaxHeight();
        }
    },
};
</script>

<style lang="scss">
@import "../../scss/variables";

header {
    background-color: $primary;
    max-height: 846px;

    .navbar {
        min-height: 6rem;
        background-color: $primary;
        display: flex;
        align-items: center;
        .navbar-brand, .navbar-tabs {
            flex-wrap: wrap;
            align-items: center;
            flex-shrink: 1;
            @media (max-width: $sticky-registration-form-breakpoint) {
                position: static;
                width: auto;
            }
        }
        .gallery-link {
            margin-left: auto;
        }

        .navbar-item {
            padding-left: 8px;
        }
        .navbar-item:first-child{
            margin-right: 3rem;
        }
    }

    .logo {
        min-height: 2.5rem;
        width: auto;
    }
}

.img-bg {
    position: absolute;
    top: -200px;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 100;
    opacity: 0.08;
    pointer-events: none;
}
</style>
