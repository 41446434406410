export default {
    gallery: {
        show: false,
    },
    agenda: [
        {
            time_range: '18:00-19:00',
            title: 'Arrival of Guests',
            description: "Come on in, grab a drink, and\u00a0let's get this party started!",
        },
        {
            time_range: '19:00-19:30',
            title: 'Welcoming Word',
            description: "Our CEO, Milan Simek, will pop in to say a QUICK 'hi' and\u00a0welcome everyone.",
        },
        {
            time_range: '19:30-19:45',
            title: 'Dance Exhibition',
            description: "Watch the\u00a0Czech dance Champions Matyáš Felcman and Nela Kosková twirl to\u00a0the\u00a0rhythm of\u00a0Latin America.",
        },
        {
            time_range: '20:00',
            title: 'Refreshments Accompanied by Live Band and Quiet Music',
            description: 'Enjoy a\u00a0selection of\u00a0appetizers and\u00a0drinks while a\u00a0live band plays soothing background music.',
        },
        {
            time_range: '21:30-22:00',
            title: 'Kahoot Quiz',
            description: 'Test your knowledge and\u00a0have fun with our\u00a0interactive Kahoot quiz.',
        },
        {
            time_range: '22:00-22:30',
            title: 'Dance Lesson',
            description: 'Master the\u00a0art of\u00a0Cuban salsa with guidance from\u00a0our\u00a0dance Champions.',
        },
        {
            time_range: '22:30-24:00',
            title: 'Live Band and Dance Music',
            description: "Continue enjoying the energetic music as\u00a0our\u00a0live band keeps the\u00a0party going.",
        },
        {
            time_range: '24:00-03:00',
            title: 'DJ and Dance Music',
            description: 'Our DJ takes over with a\u00a0mix\u00a0of\u00a0popular hits to\u00a0keep you dancing into the early hours.',
        },
        // {
        //     time_range: '23:30-00:00',
        //     title: 'Live Band and Dance Music',
        //     description: 'Continue enjoying the energetic music as our live band keeps the party going.',
        // },
        // {
        //     time_range: '00:00-03:00',
        //     title: 'DJ and Dance Music',
        //     description: 'Our DJ takes over with a mix of popular hits to keep you dancing into the early hours.',
        // },
        {
            time_range: '03:00',
            title: 'Closing of Sewio Gala',
            description: null,
        },
    ]
};