<template>
    <div>
        <section id="hero-section">
            <div class="hero" ref="hero">
                <div class="container">
                    <div class="lines-bg part-1">
                        <div class="lines-bg part-12">
                            <thank-you-hero/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="container">
            <div class="lines-bg part-2">
                <div class="lines-bg part-22">
                    <div class="section homepage--main">
                        <div class="homepage-main">
                            <div class="columns is-centered">
                                <div class="column is-8-desktop is-12">
                                    <div class="thank-you-text normal-text">
                                        <span>Your registration for the Sewio Gala Evening is confirmed!</span>
                                        <span>We've sent a&nbsp;confirmation email to&nbsp;your mailbox with&nbsp;more details. </span>
                                        <span>Looking forward to&nbsp;celebrating with&nbsp;you!</span>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-12">
                                    <div class="venue-image">
                                        <img
                                            src="../../assets/venue_place_mobile.jpg"
                                            alt="Venue"
                                            class="venue-image is-hidden-tablet"
                                        />
                                        <img
                                            src="../../assets/venue_place_desktop.jpg"
                                            alt="Venue"
                                            class="venue-image is-hidden-mobile"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Hero from "../common/Hero";
import RegistrationBox from "../molecules/RegistrationBox";
import HeroInfo from "../molecules/HeroInfo";
import HomepageHero from "@/components/molecules/HomepageHero.vue";
import HomepageContent from "@/components/organisms/HomepageContent.vue";
import ThankYouHero from "@/components/molecules/ThankYouHero.vue";

export default {
    components: {ThankYouHero, HomepageContent, HomepageHero, HeroInfo, RegistrationBox, Hero},
};
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.homepage-main {
    margin-top: 12px !important;
}

.hero {
    @media (max-width: 768px) {
        padding-bottom: 100px;
    }
}

.thank-you-text {
    padding-top: 5rem;
    padding-bottom: 3rem;
    text-align: center;
    span {
        display: inline-block;
    }
}

.venue-image {
    text-align: center;
}

</style>
