<template>
    <div class="columns pt-4">
        <div class="column is-12">
            <h1 class="thank-you-headline">Thank You</h1>
            <div>
                <h3 class="thank-you-subtitle">for your Registation</h3>
            </div>
        </div>
    </div>
</template>

<script>
import HeroInfo from "@/components/molecules/HeroInfo.vue";

export default {
    components: {HeroInfo}
}
</script>

<style scoped lang="scss">
@import "../../scss/variables";

.thank-you-headline {
    color: white;
    font-size: 7.75rem;
    font-weight: 100;
    text-align: center;
    @media (max-width: 1400px) {
        font-size: 6.125rem;
    }
    @media (max-width: 1200px) {
        font-size: 7.375rem;
    }
    @media (max-width: 768px) {
        font-size: 4rem;
        white-space: normal;
    }
    @media (max-width: 375px) {
        font-size: 4rem;
    }
}

.thank-you-subtitle {
    color: white;
    font-size: 2rem;
    font-weight: 300;
    text-align: center;
    @media (min-width: 1200px) {
        padding-bottom: 8rem;
    }
}
</style>