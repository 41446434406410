import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import Buefy from "buefy";
import  {ValidationObserver, ValidationProvider, localize, extend} from "vee-validate";
import { required, email } from 'vee-validate/dist/rules';
import dictionary from "./dictionary";
// Register dictionary for validation
localize(dictionary);
extend('email', email);
extend('required', required);

import Meta from "vue-meta";
import VueScrollTo from "vue-scrollto";

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// Route Components
import Homepage from "./components/pages/Homepage";
import ThankYou from "./components/pages/ThankYou.vue";

// FontAwesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fab, faEnvelope);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(Buefy);

Vue.use(VueRouter);

Vue.use(Meta);

Vue.use(VueScrollTo);

const routes = [
  { path: "/", component: Homepage, name: "homepage" },
  { path: "/thank-you", component: ThankYou, name: "thank-you"},
  //{ path: "/gallery", component: Gallery, name: "gallery" },
];

new Vue({
  router: new VueRouter({
    mode: "history",
    // eslint-disable-next-line
    scrollBehavior: function (to, from, savedPosition) {
      if (to.hash) {
        return {selector: to.hash};
      } else {
        return {x: 0, y: 0};
      }
    },
    routes: routes,
  }),
  data: {
    config: {
      assets_domain: 'https://backend.summit2023.sewio.net',
    },
    register: false,
  },
  methods: {
    imgSourceAddDomain(content) {
      if (typeof content !== 'string' && !(content instanceof String)) {
        return content;
      }

      return content.replace(/(<img[^>]*src=")(?!https?:\/\/)([^"]+)/g, `$1${this.$root.$data.config.assets_domain}$2`);
    }
  },
  render: (h) => h(App),
}).$mount("#app");
