<template>
    <div>
        <div class="agenda-item" v-for="item in content.agenda" :key="item.time_range">
            <div class="time-range">{{ item.time_range }}</div>
            <div class="agenda-title">
                <div>{{ item.title }}</div>
                <div class="agenda-description">{{ item.description }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import content from "@/content";

export default {
    data() {
        return {
            content: content,
        }
    },
}
</script>

<style scoped lang="scss">
@import "../../scss/variables";
.agenda-item {
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #7D9986;
    display: flex;
    align-items: baseline;
    margin: 0;
    &:first-child {
        padding-top: 0;
    }
    .time-range {
        color: $primary-dark;
        font-size: 1rem;
        font-weight: 300;
        &:first-child {
            padding-right: 1rem;
            flex-basis: 18%;
        }
    }
    .agenda-title {
        color: #355941;
        font-weight: bold;
        font-size: 1.5rem;
        width: 100%;
        .agenda-description {
            font-weight: 300;
            font-size: 1rem;
        }
    }
}
</style>