<template>
    <div>
        <section id="hero-section">
            <div class="hero" ref="hero">
                <div class="container">
                    <div class="lines-bg part-1">
                        <div class="lines-bg part-12">
                            <homepage-hero/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="container">
            <div class="lines-bg part-2">
                <div class="lines-bg part-22">
                    <div class="section homepage--main">
                        <div class="homepage-main">
                            <homepage-content/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Hero from "../common/Hero";
import RegistrationBox from "../molecules/RegistrationBox";
import HeroInfo from "../molecules/HeroInfo";
import HomepageHero from "@/components/molecules/HomepageHero.vue";
import HomepageContent from "@/components/organisms/HomepageContent.vue";
import ThankYouHero from "@/components/molecules/ThankYouHero.vue";

export default {
    components: {ThankYouHero, HomepageContent, HomepageHero, HeroInfo, RegistrationBox, Hero},
};
</script>

<style lang="scss">
@import "../../scss/variables";

</style>
