<template>
    <div>
        <div class="columns pt-4">
            <div class="column is-7-widescreen is-12-tablet pb-0-mobile" ref="headingParent">
                <div class="gala-headline-wrapper">
                    <h1 class="gala-headline" ref="heading">Sewio Gala Evening</h1>
                </div>
            </div>
        </div>
        <div class="columns pb-lines">
            <div class="column is-7-widescreen is-12-tablet pt-mb-0-mobile">
                <hero-info/>
            </div>
        </div>
    </div>
</template>

<script>
import HeroInfo from "@/components/molecules/HeroInfo.vue";
import debounce from "buefy/src/utils/debounce";

export default {
    components: {HeroInfo},
    data() {
        return {
            ratio: 5.3,
        }
    },
    methods: {
        changeFontSize() {
            if (!this.$refs.heading) return;
            if (!this.$refs.headingParent) return;
            const heading = this.$refs.heading;
            const containerWidth = this.$refs.headingParent.clientWidth; // Use clientWidth for the parent container
            const maxFontSize = 77;
            heading.style.fontSize = "";
            // if screen width is > 1400px set to 86px
            if (window.innerWidth > 1400) {
                heading.style.fontSize = maxFontSize + "px";
                return;
            }

            const headingWidth = heading.offsetWidth;
            const fontSize = Math.min(
                ((containerWidth / headingWidth) * maxFontSize) / this.ratio,
                maxFontSize
            );
            heading.style.fontSize = fontSize + "px";
        },
    },
    mounted() {
        this.changeFontSize = debounce(this.changeFontSize, 25);
        window.addEventListener('resize', this.changeFontSize);
        this.$nextTick(() => {
            this.changeFontSize();
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.changeFontSize);
    },
}
</script>

<style scoped lang="scss">
@import "../../scss/variables";
.gala-headline-wrapper {
    border-bottom: $secondary 2px solid;
    display: inline-flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 1rem;
}
.pt-mb-0-mobile {
    @media (max-width: 768px) {
        padding-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
.pb-0-mobile {
    @media (max-width: 768px) {
        padding-bottom: 0 !important;
    }
}
.gala-headline {
    color: $gold;
    font-weight: bold;
    text-align: center;
    position: relative;
    z-index: 100;
    width: fit-content;
    white-space: nowrap;
}
</style>