var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('registration-box'),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-8-widescreen is-12-tablet"},[_c('div',{staticClass:"pt-5rem"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12"},[_vm._m(0),_c('p',{staticClass:"normal-text"},[_vm._v(" We're turning 10 and we want you to be part of the festivities. Dance the night away with us at the Sewio Gala, as we honor 10 remarkable years filled with innovation, strong partnerships, and achievements. Immerse yourself in dancing lessons, enjoy live music, groove to DJ tunes, and take part in our charity auction. A nice evening to remember the past and look forward to the future. Let´s mark this milestone in style together. ")]),_vm._m(1),_c('agenda-list'),_vm._m(2),_c('p',{staticClass:"normal-text"},[_vm._v(" The gala will take place at the beautiful four-star Courtyard by Mariott hotel. It´s located just five minutes by public transport from the center of Brno. If you prefer traveling by car, the hotel offers convenient on-site parking. ")]),_vm._m(3),_vm._m(4),_c('p',{staticClass:"normal-text"},[_vm._v(" The dress code for our gala is more semi-formal rather than strictly black tie. This means that men are encouraged to wear darker-colored suits, while they can express their creativity through colorful accents. Women have the option to wear shorter dresses, long dresses with subtle hints of color, or even stylish overalls. ")]),_vm._m(5),_vm._m(6),_vm._m(7),_c('p',{staticClass:"normal-text"},[_vm._v(" Other accommodation options within walking distance from the event venue are: ")]),_vm._m(8),_c('br'),_c('br'),_c('img',{staticClass:"venue-image is-hidden-tablet",attrs:{"src":require("../../assets/rooms_mobile.jpg"),"alt":"Marriott hotel rooms"}}),_c('img',{staticClass:"venue-image is-hidden-mobile",attrs:{"src":require("../../assets/rooms_desktop.jpg"),"alt":"Marriott hotel rooms"}})],1)])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bordered-title"},[_c('p',{staticClass:"title is-2"},[_vm._v("About Sewio Gala Evening")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bordered-title mt-5rem mb-2rem"},[_c('p',{staticClass:"title is-2"},[_vm._v("Program")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bordered-title mt-5rem"},[_c('p',{staticClass:"title is-2"},[_vm._v("Sewio Gala Evening in Courtyard by Marriott Brno")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-6"},[_c('img',{staticClass:"venue-image is-hidden-tablet",attrs:{"src":require("../../assets/venue_place_mobile.jpg"),"alt":"Venue"}}),_c('img',{staticClass:"venue-image is-hidden-mobile",attrs:{"src":require("../../assets/venue_place_desktop.jpg"),"alt":"Venue"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bordered-title mt-5rem"},[_c('p',{staticClass:"title is-2"},[_vm._v("What Is the Appropriate Attire for the Event?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-6"},[_c('img',{staticClass:"venue-image is-hidden-tablet",attrs:{"src":require("../../assets/dress_code_mobile.jpg"),"alt":"Dress code"}}),_c('img',{staticClass:"venue-image is-hidden-mobile",attrs:{"src":require("../../assets/dress_code_desktop.jpg"),"alt":"Dress code"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bordered-title mt-5rem"},[_c('p',{staticClass:"title is-2"},[_vm._v("Looking for a Place to Stay?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"normal-text mb-2"},[_c('a',{attrs:{"href":"https://www.marriott.com/event-reservations/reservation-link.mi?id=1700130784363&key=GRP&app=resvlink","target":"_blank"}},[_vm._v("Get a room directly at the Marriott hotel at a discounted price.")]),_c('br'),_c('span',{staticClass:"text-small"},[_vm._v("(This discount is only valid till the 5"),_c('sup',[_vm._v("th")]),_vm._v(" of January, 2024)")]),_c('br'),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"https://www.sharingham.cz/","target":"_blank"}},[_vm._v("Hotel Sharingham")]),_vm._v(", double rooms available from 1550 CZK")]),_c('li',[_c('a',{attrs:{"href":"https://www.fairhotel.cz/","target":"_blank"}},[_vm._v("Fairhotel")]),_vm._v(", double rooms available from 2600 CZK")]),_c('li',[_c('a',{attrs:{"href":"https://www.hotelinternational.cz/","target":"_blank"}},[_vm._v("Hotel International")]),_vm._v(", double rooms available from 2500 CZK")])])
}]

export { render, staticRenderFns }